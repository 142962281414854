import MainBody from '@/components/layouts/MainBody'

/**
 * Todo: 에러 페이지 디자인이 필요합니다.
 * @constructor
 */
const PageError = () => {
  return (
    <MainBody>
      <div className="flex h-screen w-full items-center justify-center">
        <span className="body1 font-bold">없는 페이지 입니다.</span>
      </div>
    </MainBody>
  )
}

export default PageError
